import React from 'react'
import { PrimaryButton } from '@posten-hedwig/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCommentsAlt } from '@fortawesome/pro-solid-svg-icons'


export const ChatButton = ({ text }) => {
    const handleClick = () => {
        window.kindlyChat.openChat()
    }
    
    return (
        <div className='hw-block hw-block--mb'>
            <PrimaryButton onClick={() => handleClick()}>
                <FontAwesomeIcon icon={faCommentsAlt} />
                &nbsp;{text}
            </PrimaryButton>
        </div>
    )
}

